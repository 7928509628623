exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-contentful-careers-slug-js": () => import("./../../../src/pages/careers/{contentfulCareers.slug}.js" /* webpackChunkName: "component---src-pages-careers-contentful-careers-slug-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contentful-faculty-page-slug-js": () => import("./../../../src/pages/{contentfulFacultyPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-faculty-page-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-lab-js": () => import("./../../../src/templates/lab.js" /* webpackChunkName: "component---src-templates-lab-js" */),
  "component---src-templates-research-project-js": () => import("./../../../src/templates/research-project.js" /* webpackChunkName: "component---src-templates-research-project-js" */),
  "component---src-templates-students-projects-js": () => import("./../../../src/templates/students-projects.js" /* webpackChunkName: "component---src-templates-students-projects-js" */),
  "component---src-templates-team-member-profile-js": () => import("./../../../src/templates/team-member-profile.js" /* webpackChunkName: "component---src-templates-team-member-profile-js" */)
}

